import React from 'react'
import styles from './Home.module.css'
import Footer from '../../components/Footer'
import Background from '../../components/ResponsiveImage'
import { BackgroundHome } from '../../assets/images-data/BackgroundData'
/*import backgroundTV from '../../assets/images/edjp-home-background.webp'
import backgroundDescktop from '../../assets/images/edjp-home-background-hd.webp'
import backgroundMobile from '../../assets/images/edjp-home-background-hq.webp'*/

function Home() {
  /*const data = [
    {
      src: backgroundMobile,
      media: '(max-width: 768px)',
      alt: 'EDJP background',
    },
    {
      src: backgroundDescktop,
      media: '(max-width: 1980px)',
      alt: 'EDJP background',
    },
    {
      src: backgroundTV,
      media: '(min-width: 1981px)',
      alt: 'EDJP background',
    },
  ]*/

  return (
    <main className={styles.main}>
      <Background data={BackgroundHome} />
      <section className={styles.presentation}>
        <div className={styles.title}>
          Easy
          <br />
          DJ
          <br />
          Party
        </div>
      </section>
      <div className={styles.footerHome}>
        <Footer />
      </div>
    </main>
  )
}

export default Home
