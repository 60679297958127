import React from 'react'
import styles from './Footer.module.css'

function Footer() {
  return (
    <footer>
      <div className={styles.footerBackground}></div>
      <div className={styles.edjpContent}>
        <div className={styles.copyright}>
          <p>℗ 2023&nbsp;</p>
          <p>
            <b>Easy DJ Party</b>
          </p>
          <p>&nbsp;under construction</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
