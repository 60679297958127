import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'

/*import Header from './components/Header'*/

import Error404 from './pages/Error404'

function EdjpRouter() {
  return (
    <Router>
      {/*<Header />*/}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Router>
  )
}

export default EdjpRouter
