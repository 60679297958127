import backgroundTV from '../../assets/images/edjp-home-background.webp'
import backgroundDescktop from '../../assets/images/edjp-home-background-hd.webp'
import backgroundMobile from '../../assets/images/edjp-home-background-hq.webp'

export const BackgroundHome = [
  {
    src: backgroundMobile,
    media: '(max-width: 768px)',
    alt: 'EDJP background',
  },
  {
    src: backgroundDescktop,
    media: '(min-width: 769px) and (max-width: 1980px)',
    alt: 'EDJP background',
  },
  {
    src: backgroundTV,
    media: '(min-width: 1981px)',
    alt: 'EDJP background',
  },
]
