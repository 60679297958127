import React from 'react'
import styles from './ResponsiveImage.module.css'

function Background({ data }) {
  return (
    <div className={styles.image}>
      <picture>
        {data.map((resource, index) => (
          <source
            key={`${resource.alt}:${index}`}
            srcset={resource.src}
            media={resource.media}
          />
        ))}
        <img src={data[0].src} alt={data[0].alt} />
      </picture>
    </div>
  )
}

export default Background
